import { Injectable } from '@angular/core';
import {
  CreatePersonalEventRequest,
  GetPersonalEventRequest,
  PatchPersonalEventRequest,
} from '@models/personal-event-request';
import { CreatePersonalEventResult } from '@models/createPersonalEventResult';
import { first, map, Observable } from 'rxjs';
import { PersonalEventDto } from '@models/personalEventDto';
import { DeletePersonalEventByIdResult } from '@models/deletePersonalEventByIdResult';
import { PersonalEventApiService } from './api';
import { GetPersonalEventsResult } from '@models/getPersonalEventsResult';

@Injectable({ providedIn: 'root' })
export class PersonalEventService {
  constructor(private personalEventApiService: PersonalEventApiService) {}

  getEvents(
    params: GetPersonalEventRequest,
  ): Observable<GetPersonalEventsResult> {
    return this.personalEventApiService.getEvents(params);
  }

  updatePersonalEvent(
    body: PatchPersonalEventRequest,
    id: number,
  ): Observable<PersonalEventDto> {
    return this.personalEventApiService
      .updateEvent(body, id)
      .pipe(map((res) => res.event));
  }

  createPersonalEvent(
    body: CreatePersonalEventRequest,
  ): Observable<CreatePersonalEventResult> {
    return this.personalEventApiService.createEvent(body);
  }

  getPersonalEventById(id: number): Observable<PersonalEventDto | undefined> {
    return this.personalEventApiService.getEventById(id).pipe(
      map((resp) => resp?.event),
      first(),
    );
  }

  deleteEvent(
    id: number,
    isDeleteAll?: boolean,
  ): Observable<DeletePersonalEventByIdResult> {
    return this.personalEventApiService.deleteEvent(id, isDeleteAll);
  }
}
