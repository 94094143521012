import { Injectable } from '@angular/core';
import { TaskEventApiService } from '@services/api/task-event.api.service';
import { BehaviorSubject, first, Observable, tap } from 'rxjs';
import {
  CreateProjectTaskEventRequest,
  DeleteTaskEventResult,
  ProjectTaskEventDto,
  UpdateProjectTaskEventRequest,
} from '@models/task-event';

@Injectable({
  providedIn: 'root',
})
export class TaskEventService {
  constructor(private taskEventApiService: TaskEventApiService) {}

  private readonly _taskEvents$: BehaviorSubject<ProjectTaskEventDto[]> =
    new BehaviorSubject<ProjectTaskEventDto[]>([]);

  needToUpdate(update: boolean): boolean {
    return !this._taskEvents$.value.length || update;
  }

  getTaskEvents(
    update = false,
    projectTaskIds?: number[],
  ): Observable<ProjectTaskEventDto[]> {
    if (this.needToUpdate(update)) {
      this.loadTaskEvents(projectTaskIds);
    }
    return this.taskEventApiService
    .getTaskEvents(projectTaskIds);
  }

  loadTaskEvents(projectTaskIds?: number[]): void {
    this.taskEventApiService
      .getTaskEvents(projectTaskIds)
      .pipe(
        first(),
        tap((resp) => this._taskEvents$.next(resp)),
      )
      .subscribe();
  }

  getTaskEvent(id: string): Observable<ProjectTaskEventDto> {
    return this.taskEventApiService.getTaskEvent(id);
  }

  updateTaskEvent(
    id: string,
    body: UpdateProjectTaskEventRequest,
  ): Observable<ProjectTaskEventDto> {
    return this.taskEventApiService.updateTaskEvent(id, body);
  }

  createTaskEvent(
    body: CreateProjectTaskEventRequest,
  ): Observable<ProjectTaskEventDto> {
    return this.taskEventApiService.createTaskEvent(body);
  }

  deleteTaskEvent(id: string): Observable<DeleteTaskEventResult> {
    return this.taskEventApiService.deleteTaskEvent(id);
  }

  clearState(): void {
    this._taskEvents$.next([]);
  }
}
