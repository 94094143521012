import { Injectable } from '@angular/core';
import {
  CreateGroupEventRequest,
  GetGroupEventsRequest,
  GroupEventDeleteResult,
  GroupEventDto,
  GroupEventsResult,
  PatchGroupEventRequest,
  UpdateGroupMembersRequest,
} from '@models/group-events-api';
import { Observable } from 'rxjs';
import { GroupEventsApiService } from './api';

@Injectable({ providedIn: 'root' })
export class GroupEventsService {
  constructor(private groupEventsApiService: GroupEventsApiService) {}

  getEvents(params: GetGroupEventsRequest): Observable<GroupEventsResult> {
    return this.groupEventsApiService.getEvents(params);
  }

  getEventById(id: number): Observable<GroupEventDto> {
    return this.groupEventsApiService.getEventById(id);
  }

  deleteEvent(
    id: number,
    isDeleteAll?: boolean,
  ): Observable<GroupEventDeleteResult> {
    return this.groupEventsApiService.deleteEvent(id, isDeleteAll);
  }

  updateEvent(
    id: number,
    body: PatchGroupEventRequest,
  ): Observable<GroupEventDto> {
    return this.groupEventsApiService.updateEvent(id, body);
  }

  createEvent(body: CreateGroupEventRequest): Observable<GroupEventDto> {
    return this.groupEventsApiService.createEvent(body);
  }

  addMemberToAssignedGroup(
    eventId: number,
    body: UpdateGroupMembersRequest,
  ): Observable<any> {
    return this.groupEventsApiService.addMemberToAssignedGroup(eventId, body);
  }

  removeMemberFromAssignedGroup(
    eventId: number,
    employeeId: string,
  ): Observable<any> {
    return this.groupEventsApiService.removeMemberFromAssignedGroup(
      eventId,
      employeeId,
    );
  }
}
