import { Injectable } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';
import { DateFormat } from '@models/timeFormats';
import { DateRange } from '@models/modals';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  getNow(): string {
    return this.toUTC(new Date());
  }

  toUTC(date: any): string {
    return moment(date).utc().toISOString();
  }

  convertUtcToLocal(utcString: string): string {
    const localDate = moment(utcString);
    return moment(utcString)
      .add(localDate.utcOffset(), 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  getDateInFormat(utcDate: string | Date, format: DateFormat): string {
    if (!utcDate) {
      return '';
    }
    moment.locale('ru');
    return moment.utc(utcDate).local().format(format);
  }

  getRangeBounds(range: DateRange): DateRange {
    return {
      start: range.start && this.toUTC(range.start),
      end: range.end && this.toUTC(range.end),
    };
  }

  getCalendarFormatDate(range: DateRange): any[] {
    const adaptedRange = [];
    const start =
      range.start && new Date(moment(range.start).format('yy-MM-DD'));
    const end = range.end && new Date(moment(range.end).format('yy-MM-DD'));
    if (start) {
      adaptedRange.push(start);
    }
    if (end) {
      adaptedRange.push(end);
    }
    return adaptedRange;
  }

  getDateRangeInput(start: string, end?: string): string {
    let resultString = this.getDateInFormat(start, DateFormat.Short);
    if (end) {
      resultString += ` - ${this.getDateInFormat(end, DateFormat.Short)}`;
    }
    return resultString;
  }

  getGanttDuration(start: string, end: string): number {
    const duration = moment(end).diff(start, 'minutes');
    return duration;
  }

  isBefore(firstDate: string, secondDate: string, format: DateFormat): boolean {
    return moment(firstDate, format).isBefore(moment(secondDate, format));
  }

  isAfter(firstDate: string, secondDate: string, format: DateFormat): boolean {
    return moment(firstDate, format).isAfter(moment(secondDate, format));
  }
}
