import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CopyProjectRequest,
  CreateProjectRequest,
  DeleteProjectResult,
  GetProjectsRequest,
  PatchProjectRequest,
  ProjectDto,
} from '@models/project';
import { ProjectApiService } from './api';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private projectApiService: ProjectApiService) {}

  getProjects(params?: GetProjectsRequest): Observable<ProjectDto[]> {
    return this.projectApiService.getProjects(params);
  }

  getProject(id = '0'): Observable<ProjectDto> {
    return this.projectApiService.getProject(id);
  }

  updateProject(id: number, body: PatchProjectRequest): Observable<ProjectDto> {
    return this.projectApiService.updateProject(id, body);
  }

  createProject(body: CreateProjectRequest): Observable<ProjectDto> {
    return this.projectApiService.createProject(body);
  }

  copyProject(id: number, body: CopyProjectRequest): Observable<ProjectDto> {
    return this.projectApiService.copyProject(id, body);
  }

  deleteProject(id: number): Observable<DeleteProjectResult> {
    return this.projectApiService.deleteProject(id);
  }
}
