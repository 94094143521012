import { Injectable } from '@angular/core';
import { first, map, Observable } from 'rxjs';
import {
  AbsenceEventDto,
  CreateAbsenceEventRequest,
  CreateAbsenceEventResponse,
  DeleteAbsenceEventResult,
  GetAbsenceEventRequest,
  GetAbsenceEventsResult,
  PatchAbsenceEventRequest,
} from '@models/absence';
import { AbsenceEventApiService } from './api';

@Injectable({ providedIn: 'root' })
export class AbsenceEventService {
  constructor(private absenceEventApiService: AbsenceEventApiService) {}

  getEvents(
    params: GetAbsenceEventRequest,
  ): Observable<GetAbsenceEventsResult> {
    return this.absenceEventApiService.getEvents(params);
  }

  createAbsenceEvent(
    body: CreateAbsenceEventRequest,
  ): Observable<CreateAbsenceEventResponse> {
    return this.absenceEventApiService.createEvent(body);
  }

  updateAbsenceEvent(
    body: PatchAbsenceEventRequest,
    id: number,
  ): Observable<AbsenceEventDto> {
    return this.absenceEventApiService.updateAbsenceEvent(body, id);
  }

  getAbsenceEventById(id: number): Observable<AbsenceEventDto | undefined> {
    return this.absenceEventApiService.getEventById(id).pipe(
      map((resp) => resp?.event),
      first(),
    );
  }

  deleteEvent(id: number): Observable<DeleteAbsenceEventResult> {
    return this.absenceEventApiService.deleteEvent(id);
  }
}
